/*import axios from 'util/Api'
import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    VERIFY_TOKEN
  } from "../../constants/ActionTypes";*/

const auth =  store => next => action => {

        //const dataToken = localStorage.getItem('token')
        //console.log("dataToken ######### ",dataToken )

        next(action);
       
  };

  export default auth