import React, {Component} from "react";
import {connect} from "react-redux";
import {Menu, Icon} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
    NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
    NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
    THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";


class SidebarContent extends Component {

    getNoHeaderClass = (navStyle) => {
        if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
            return "gx-no-header-notifications";
        }
        return "";
    };
    getNavStyleSubMenuClass = (navStyle) => {
        if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
            return "gx-no-header-submenu-popup";
        }
        return "";
    };

    render() {
        const {themeType, navStyle, pathname} = this.props;
        const selectedKeys = pathname.substr(1);
        const defaultOpenKeys = selectedKeys.split('/')[1];

        return (<Auxiliary>
            {/* Logo */}
            <SidebarLogo/>
            <div className="gx-sidebar-content">
                {/* User Info */}
                <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
                    <UserProfile/>
                    <AppsNavigation/>
                </div>

                {/* Menu */}
                <CustomScrollbars className="gx-layout-sider-scrollbar">
                    <Menu
                        defaultOpenKeys={[defaultOpenKeys]}
                        selectedKeys={[selectedKeys]}
                        theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
                        mode="inline">

                        <Menu.Item key="dashboard">
                            <Link to="/dashboard"><i className="icon icon-home"/>
                                <IntlMessages id="sidebar.dashboard"/>
                            </Link>
                        </Menu.Item>
                        
                        <Menu.Item key="clients/list">
                                <Link to="/clients/list">
                                    <Icon type="api" />
                                    <span>API - Landing</span>
                                </Link>
                        </Menu.Item>

                        <Menu.Item key="business/list">
                                <Link to="/business/list">
                                    <i className="icon icon-company"/>
                                    <span>Negocios</span>
                                </Link>
                        </Menu.Item>

                        <Menu.Item key="templates/list">
                                <Link to="/templates/list">
                                    <Icon type="diff" />
                                    <span>Plantillas</span>
                                </Link>
                        </Menu.Item>

                        <Menu.Item key="mails/send/list">
                                <Link to="/mails/send/list">
                                    <Icon type="form" />
                                    <span>Enviar Correo</span>
                                </Link>
                        </Menu.Item>

                        {/* <SubMenu key="mails" className={this.getNavStyleSubMenuClass(navStyle)}
                            title={<span> <i className="icon icon-email"/>
                            <IntlMessages id="sidebar.mails"/></span>}>

                            <Menu.Item key="mails/templates/list">
                                <Link to="/mails/templates/list">
                                    <Icon type="diff" />                                
                                    <IntlMessages id="sidebar.mails.templates"/>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="mails/contact/list">
                                <Link to="/mails/contact/list">
                                    <i className="icon icon-listing-dbrd"/>
                                    <IntlMessages id="sidebar.mails.contact"/>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="mails/send/list">
                                <Link to="/mails/send/list">
                                    <Icon type="form" />
                                    <IntlMessages id="sidebar.mails.send"/>
                                </Link>
                            </Menu.Item>
                        </SubMenu> */}

                        <Menu.Item key="users">
                            <Link to="/users/list"><i className="icon icon-user"/>
                                <IntlMessages id="users"/>
                            </Link>
                        </Menu.Item>
                    </Menu>
                </CustomScrollbars>
            </div>
            </Auxiliary>
        );
    }
}

SidebarContent.propTypes = {};
const mapStateToProps = (state) => {
  const {navStyle, themeType, locale, pathname} = state.settings;
  return {navStyle, themeType, locale, pathname}
};

export default connect(mapStateToProps)(SidebarContent);

