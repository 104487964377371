import {
    GET_CONTACT,
    GET_ONE_CONTACT,
    ADD_CONTACT,
    EDIT_CONTACT,
    DELETE_CONTACT,
    FAVORITE_CONTACT,
    DELETE_MULTIPLE_CONTACT,
    STATUS_REQUEST_CONTACT
}from "../../constants/ActionTypes";

const INIT_STATE = {
    business: {}
};

export default function(state = INIT_STATE, action) {
    switch(action.type) {
        case GET_CONTACT: 
            return {
                ...state,
                business: action.payload
            }
        case GET_ONE_CONTACT:
            return {
                ...state,
                business: action.payload
            }
        case ADD_CONTACT:
            return {
                ...state,
                business:{
                    id: state.business.id,
                    name: state.business.name,
                    contacts: [...state.business.contacts, action.payload]
                }
            }
        case EDIT_CONTACT:
            return {
                ...state,
                business: {
                    id: state.business.id,
                    name: state.business.name,
                    contacts: state.business.contacts.map(
                        key => key.id === action.payload.id
                        ? (key = action.payload)
                        : key
                    )
                }
            }
        case DELETE_CONTACT:
            return {
                ...state,
                business: {
                    id: state.business.id,
                    name: state.business.name,
                    contacts: state.business.contacts.filter(key => key.id !== action.payload)
                }
            }
        case DELETE_MULTIPLE_CONTACT:
            let contactFilter = [];

            for (let i = 0; i < action.payload.length; i++) {
                if( i === 0 )
                    contactFilter = state.business.contacts.filter(key => key.id !== action.payload[i]);
                else
                    contactFilter = contactFilter.filter(key => key.id !== action.payload[i]);
            }
            
            return {
                ...state,
                business: {
                    id: state.business.id,
                    name: state.business.name,
                    contacts: contactFilter
                }
            }
        case FAVORITE_CONTACT:
            return {
                ...state,
                business: {
                    id: state.business.id,
                    name: state.business.name,
                    contacts: state.business.contacts.map(
                        key => {
                            if( key.id === action.payload )
                            {
                                key.starred = (key.starred === 1) ? 0 : 1;
                            }
                            
                            return key;
                        } 
                    )
                } 
            }
        case STATUS_REQUEST_CONTACT:
            return {
                ...state,
                status_request: action.payload
            }
        default:
            return state;
    }
}