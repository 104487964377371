import * as React from 'react';

import Admin from './Admin';
import User from './User';

const Dashboard = () => {
    //const {display} = JSON.parse(localStorage.getItem('user'));

    /*switch (display) {
        case 'admin': {
            return (
                <Admin />
            )
        }
        case 'user': {
            return (
                <User />
            )
        }
        default: return <User />;
    }*/
    return (
        <React.Fragment>
            <Admin />
            <User />
        </React.Fragment>
    )    
};

export default Dashboard;