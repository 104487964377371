import {
    GET_MAILS,
    GET_ONE_MAIL,
    GET_SELECT_BUSINESS,
    GET_SELECT_CONTACT,
    GET_SELECT_TEMPLATES,
    RESET_STATE_MAIL,
    SEND_MAIL,
    STATUS_REQUEST_MAIL
}from "../../constants/ActionTypes";

const INIT_STATE = {
    mails: [],
    mail: null,
    select_business: [],
    select_contact: [],
    select_template: [],
    status_request: false
};

export default function(state = INIT_STATE, action) {
    switch(action.type) {
        case GET_MAILS: 
            return {
                ...state,
                mails: action.payload
            }
        case GET_ONE_MAIL:
            return {
                ...state,
                mail: action.payload
            }
        case GET_SELECT_BUSINESS:
            return {
                ...state,
                select_business: action.payload
            }
        case GET_SELECT_CONTACT:
            return {
                ...state,
                select_contact: action.payload
            }
        case GET_SELECT_TEMPLATES:
            return {
                ...state,
                select_template: action.payload
            }
        case SEND_MAIL:
            return {
                ...state,
                mails: [...state.mails, action.payload]
            }
        case STATUS_REQUEST_MAIL:
            return {
                ...state,
                status_request: action.payload
            }
        case RESET_STATE_MAIL:
            return {
                mails: [],
                mail: null,
                select_business: [],
                select_contact: [],
                select_template: [],
                status_request: false
            }
        default:
            return state;
    }
}