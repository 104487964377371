import antdSA from "antd/lib/locale-provider/ca_ES";
import appLocaleData from "react-intl/locale-data/es";
import saMessages from "../locales/es_ES.json";
import opeMessages from "../locales/es_operations.json";

const saLang = {
  messages: {
    ...saMessages,
    ...opeMessages
  },
  antd: antdSA,
  locale: 'es',
  data: appLocaleData
};
export default saLang;
