import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    INIT_URL,
    SIGNOUT_USER_SUCCESS,
    USER_TOKEN_SET,
    VERIFY_TOKEN
} from "../../constants/ActionTypes";
import axios from 'util/Api'

export const setInitUrl = (url) => {
    return {
        type: INIT_URL,
        payload: url
    };
};

export const userSignUp = ({email, password, name}) => {
    return async (dispatch) => {
        dispatch({type: FETCH_START});
        try {
            const {data} = await axios.post('register', {
                email: email,
                password: password,
                name: name
            });

            if (!data.success) { throw data; }

            const user = {name: data.user.name, email: data.user.email, display: data.user.role};

            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("token", JSON.stringify(data.token.access_token));

            axios.defaults.headers.common['access-token'] = "Bearer " + data.token.access_token;

            dispatch({type: FETCH_SUCCESS});
            dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
            
        } catch (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
        }
    }
};

export const userSignIn = ({email, password}) => {
    return async (dispatch) => {
        dispatch({type: FETCH_START});
        try {
            const {data} = await axios.post('login', { email: email, password: password});

            if (!data.success) { throw data; }

            const user = {name: data.user.name, email: data.user.email, display: data.user.role};

            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("token", JSON.stringify(data.token.access_token));

            axios.defaults.headers.common['access-token'] = "Bearer " + data.token.access_token;

            dispatch({type: FETCH_SUCCESS});
            dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
            dispatch({type: VERIFY_TOKEN, payload: true});
            
        } catch (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
        }
    }
};

export const verifyToken = (dataToken) => {
    return async (dispatch) => {
        dispatch({type: FETCH_START});
        if(dataToken){
            try {
                let token = dataToken.replace(/['"]+/g, '')
                const data = await axios.get('validate-token',{ 
                    headers: {
                    "Content-Type" : `application/json`,
                    "Authorization" : `Bearer ${token}`
                    } 
                });

                if (!data.data.success) { throw data; }

                dispatch({type: FETCH_SUCCESS});
                dispatch({type: VERIFY_TOKEN, payload: true});
            
            } catch (error) {
                dispatch({type: VERIFY_TOKEN, payload: false});
                dispatch({type: SIGNOUT_USER_SUCCESS});
                dispatch({type: FETCH_ERROR, payload: error.message});

                setTimeout(() => {
                    dispatch({type: VERIFY_TOKEN, payload: true});
                }, 2000);
            }
        }else{
            dispatch({type: FETCH_SUCCESS});
            dispatch({type: VERIFY_TOKEN, payload: true});
        }
    }
};

export const userSignOut = () => {
    var token = localStorage.getItem("token");
    return async (dispatch) => {
        dispatch({type: FETCH_START});
        try {
            
            const {data} = await axios.post('logout', { headers: {"Authorization" : `Bearer ${token}`} });
            if (!data.success) { throw data; }
            
            setTimeout(() => {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                dispatch({type: FETCH_SUCCESS});
                dispatch({type: SIGNOUT_USER_SUCCESS});
                dispatch({type: VERIFY_TOKEN, payload: true});
            }, 900);
            
        } catch (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
        }
    }
};
