// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

// Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_START_MESSAGE = 'FETCH_START_MESSAGE';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

// Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_TOKEN_SET = 'user_token_set';
export const VERIFY_TOKEN = 'VERIFY_TOKEN';

// Crud const
export const GET_CRUD = 'GET_CRUD';
export const GET_ONE_CRUD = 'GET_ONE_CRUD';
export const ADD_CRUD = 'ADD_CRUD';
export const EDIT_CRUD = 'EDIT_CRUD';
export const DELETE_CRUD = 'DELETE_CRUD';
export const STATUS_REQUEST_CRUD = 'STATUS_REQUEST_CRUD';
export const RESET_STATE_CRUD = 'RESET_STATE_CRUD';

// Contact const
export const GET_CONTACT = 'GET_CONTACT';
export const GET_ONE_CONTACT = 'GET_ONE_CONTACT';
export const ADD_CONTACT = 'ADD_CONTACT';
export const EDIT_CONTACT = 'EDIT_CONTACT';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const STATUS_REQUEST_CONTACT = 'STATUS_REQUEST_CONTACT';
export const DELETE_MULTIPLE_CONTACT = 'DELETE_MULTIPLE_CONTACT';
export const FAVORITE_CONTACT = 'FAVORITE_CONTACT';

// Mails const
export const GET_MAILS = 'GET_MAILS';
export const GET_ONE_MAIL = 'GET_ONE_MAIL';
export const GET_SELECT_BUSINESS = 'GET_SELECT_BUSINESS';
export const GET_SELECT_CONTACT = 'GET_SELECT_CONTACT';
export const GET_SELECT_TEMPLATES = 'GET_SELECT_TEMPLATES';
export const SEND_MAIL = 'SEND_MAIL';
export const STATUS_REQUEST_MAIL = 'STATUS_REQUEST_MAIL';
export const RESET_STATE_MAIL = 'RESET_STATE_MAIL';