import {combineReducers} from "redux";
import {routerReducer} from "react-router-redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import Crud from "./Crud";
import Contact from "./Contact";
import Mail from "./Mail";

const reducers = combineReducers({
    routing: routerReducer,
    settings: Settings,
    auth: Auth,
    commonData: Common,
    crud: Crud,
    business: Contact,
    mail: Mail
});

export default reducers;
