import React from "react";
import {Col, Row} from "antd";

import Auxiliary from "util/Auxiliary";
import GrowthCard from "./CRM/GrowthCard";
import IconWithTextCard from "./CRM/IconWithTextCard";
import {trafficData} from "./CRM/data";

const User = () => {
    return (
        <Auxiliary>
            <Row>
                <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <IconWithTextCard cardColor="cyan" icon="diamond" title="09" subTitle="Projects"/>
                </Col>
                <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <IconWithTextCard cardColor="orange" icon="tasks" title="687" subTitle="Tasks"/>
                </Col>
                <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <IconWithTextCard cardColor="teal" icon="team" title="04" subTitle="Teams"/>
                </Col>
                <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                    <IconWithTextCard cardColor="red" icon="files" title="09" subTitle="Files"/>
                </Col>

                <Col xl={8} lg={12} md={8} sm={24} xs={24}>
                    <GrowthCard trafficData={trafficData}/>
                </Col>
            </Row>
        </Auxiliary>
    );
};

export default User;
