import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";
import Main from "./main/index";


const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}dashboard`} component={Main}/>
      <Route path={`${match.url}users`} component={asyncComponent(() => import('./Users'))}/>
      <Route path={`${match.url}business`} component={asyncComponent(() => import('./Business'))}/>
      <Route path={`${match.url}clients`} component={asyncComponent(() => import('./Clients'))}/>
      <Route path={`${match.url}templates`} component={asyncComponent(() => import('./Templates'))}/>
      <Route path={`${match.url}mails`} component={asyncComponent(() => import('./Mails'))}/>
    </Switch>
  </div>
);

export default App;
