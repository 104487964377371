import {
    GET_CRUD,
    GET_ONE_CRUD,
    ADD_CRUD,
    EDIT_CRUD,
    DELETE_CRUD,
    RESET_STATE_CRUD,
    STATUS_REQUEST_CRUD
}from "../../constants/ActionTypes";

const INIT_STATE = {
    data: []
};

export default function(state = INIT_STATE, action) {
    switch(action.type) {
        case GET_CRUD: 
            return {
                ...state,
                data: action.payload
            }
        case GET_ONE_CRUD:
            return {
                ...state,
                oneData: action.payload
            }
        case ADD_CRUD:
            return {
                ...state,
                data: [...state.data, action.payload]
            }
        case EDIT_CRUD:
            return {
                ...state,
                data: state.data.map(
                    key => key.id === action.payload.id
                    ? (key = action.payload)
                    : key
                )
            }
        case DELETE_CRUD:
            return {
                ...state,
                data: state.data.filter(key => key.id !== action.payload)
            }
        case STATUS_REQUEST_CRUD:
            return {
                ...state,
                status_request: action.payload
            }
        case RESET_STATE_CRUD:
            return {
                data: [],
                oneData: null
            }
        default:
            return state;
    }
}