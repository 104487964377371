import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";

class UserProfile extends Component {

  render() {
    const authUser = JSON.parse(localStorage.getItem('user'));
    
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li>My Account</li>
        <li onClick={() => this.props.userSignOut()}>Salir
        </li>
      </ul>
    );

    return (
      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
            <Avatar src='https://via.placeholder.com/150x150' className="gx-size-40 gx-pointer gx-mr-3" alt="Avatar"/>
            <span className="gx-avatar-name">{authUser ? authUser.name : "Loading"}
              <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/>
            </span>
        </Popover>
      </div>
    )
  }
}

export default connect(null, {userSignOut})(UserProfile);
